<template>
    <dashboard-layout>
        <v-card class="mt-4">
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ menu.title }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'venue.menus.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field placeholder="Menu Title" label="Menu Title" v-model="menu.title" outlined/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                :items="venues"
                                v-model="venue"
                                item-text="name"
                                item-value="id"
                                label="Venue"
                                outlined
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="! menu.id">
                        <v-col>
                            <vue2-dropzone
                                ref="myVueDropzone" id="dropzone"
                                :options="dropzoneOptions"
                                @vdropzone-success="handleSuccess"
                            ></vue2-dropzone>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="menu.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="save">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Menu from "@/models/Menu";
import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Venue from "@/models/Venue";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-show",
    components: {DashboardLayout, vue2Dropzone},
    data: function () {
        return {
            menu: {},
            dropzoneOptions: {
                url: '/api/upload',
                thumbnailWidth: 250,
                maxFilesize: 100,
                headers: {
                    Authorization: null
                },
                params: {
                    directory: '/menus'
                }
            },
            venue: null,
            venues: [],
        }
    },
    methods: {
        handleSuccess(file, res) {
            this.menu.file_url = res.url
            this.menu.title = this.menu.title ? this.menu.title : res.original_name
            this.$forceUpdate()
        },
        async save() {
            this.menu.venue_id = this.venue
            await this.menu.save()
        },
    },
    async mounted() {
        const menuId = this.$route.params.id
        this.menu = menuId ? await Menu.find(menuId) : new Menu()
        this.venues = await Venue.where('user_id', this.$auth.user().id).get()

        const token = await localStorage.getItem('auth_token_default')
        this.dropzoneOptions.headers.Authorization = "Bearer " + token
    },
}
</script>

<style scoped>

</style>
